import { Avatar, HStack, Icon, Text, theme, VStack } from "@chakra-ui/react";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { signInWithCustomToken } from "firebase/auth";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { auth } from "../../auth/config";
import { AppContainer } from "../../components/app-container";
import { ContainerLoading } from "../../components/container-loading";
import {
  useProfileLoginMutation,
  useProfilesQuery,
} from "../../graphql/generated/user-hooks";
import { Routes } from "../../helpers/router";
import { OnboardingPersonalDetails } from "../onboarding/personal-details/personal-details";
import { getTenantConfig } from "../../helpers/get-tenant-config";

export const Profiles = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [profileLogginIn, setProfileLogginIn] = useState(false);
  const profileId = searchParams.get("profile_id");
  const token = searchParams.get("token");

  const { data, loading } = useProfilesQuery({
    fetchPolicy: "network-only",
    context: {
      endpoint: "fitnext",
      headers: token ? { Authorization: `Bearer ${token}` } : undefined,
    },
    skip: Boolean(profileId),
  });

  const profiles = data?.profiles.edges ?? [];
  const validProfiles =
    profiles.filter((node) => isValidTenant(node.node.tenantId)) ?? [];

  const [profileLogin] = useProfileLoginMutation({
    context: {
      endpoint: "fitnext",
      headers: token ? { Authorization: `Bearer ${token}` } : undefined,
    },
    onCompleted: (data) => {
      setProfileLogginIn(true);
      signInWithCustomToken(auth, data.token).then(() =>
        navigate(Routes.Dashboard())
      );
    },
  });

  useEffect(() => {
    if (validProfiles.length > 0) {
      if (profileId) {
        const profile = validProfiles.find(
          (profile) => profile.node.id === profileId
        );
        if (profile) {
          profileLogin({ variables: { id: profileId } });
        }
      } else {
        if (validProfiles.length === 1) {
          const profile = validProfiles[0];
          profileLogin({ variables: { id: profile.node.id } });
        }
      }
    }
  }, [data]);

  if (
    loading ||
    !data ||
    profileLogginIn ||
    profileId ||
    validProfiles.length === 1
  ) {
    return (
      <AppContainer>
        <ContainerLoading />
      </AppContainer>
    );
  }

  if (profiles.length === 0) {
    return (
      <AppContainer maxWidth="container.lg">
        <OnboardingPersonalDetails />
      </AppContainer>
    );
  }

  return (
    <AppContainer>
      <VStack w="full" spacing={6}>
        <VStack w="full" alignItems="start">
          <Text fontSize="2xl" fontWeight={700} color="gray.900">
            {t("profiles.title")}
          </Text>
          <Text>{t("profiles.subtitle")}</Text>
        </VStack>
        <VStack w="full">
          {profiles.map(({ node }) => (
            <HStack
              role="button"
              w="full"
              key={node.id}
              cursor={isValidTenant(node.tenantId) ? "pointer" : "not-allowed"}
              px={6}
              py={4}
              background={
                isValidTenant(node.tenantId) ? "white" : theme.colors.gray[300]
              }
              _hover={{
                background(theme) {
                  if (isValidTenant(node.tenantId)) {
                    return theme.colors.gray[50];
                  } else {
                    return undefined;
                  }
                },
              }}
              onClick={
                node.tenantId !== getTenantConfig().id
                  ? undefined
                  : () => profileLogin({ variables: { id: node.id } })
              }
              borderColor="gray.200"
              borderWidth={1}
              borderRadius={4}
            >
              <Avatar
                name={node.firstName}
                src={node.avatarUrl ?? undefined}
                mr={4}
              />
              <VStack w="full">
                <Text w="full" fontSize="lg" fontWeight={500}>
                  {node.firstName} {node.lastName}
                </Text>
                {!isValidTenant(node.tenantId) ? (
                  <Text fontSize="md">{t("profiles.invalidProfile")}</Text>
                ) : null}
              </VStack>
              {isValidTenant(node.tenantId) ? (
                <Icon boxSize={6}>
                  <ChevronRightIcon />
                </Icon>
              ) : null}
            </HStack>
          ))}
        </VStack>
      </VStack>
    </AppContainer>
  );
};

const isValidTenant = (tenantId: string) => getTenantConfig().id === tenantId;
