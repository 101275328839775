import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Container,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Text,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { TFunction } from "i18next";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useSendResetPasswordMutation } from "../../graphql/generated/user-hooks";
import { Button } from "../../components/chackra-ui-button";
import * as Sentry from "@sentry/react";

type FormValues = {
  email: string;
};

const validationSchema = (t: TFunction) =>
  Yup.object({
    email: Yup.string().required(t("resetPassword.emailRequired")),
  });

export const ResetPasswordForm = () => {
  const { t } = useTranslation();
  const [alertMessage, setAlertMessage] = useState<"completed" | undefined>(
    undefined
  );
  const toast = useToast();
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
    watch,
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema(t)),
  });

  const [resetPassword] = useSendResetPasswordMutation({
    context: {
      endpoint: "fitnext",
    },
    onCompleted: () => {
      setAlertMessage("completed");
    },
    onError: (err) => {
      toast({
        title: t("resetPassword.errorToast.title"),
        description: t("resetPassword.errorToast.description"),
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      Sentry.captureException(err);
    },
  });

  const onSubmit = handleSubmit(
    (values) => resetPassword({ variables: { email: values.email } }),
    (errors, event) => {
      Sentry.captureException(new Error("Unable to submit register form"), {
        extra: {
          event,
          errors,
        },
      });
    }
  );

  const email = watch("email");

  return alertMessage === "completed" ? (
    <Alert
      status="info"
      variant="subtle"
      flexDirection="column"
      alignItems="center"
      justifyContent="stretch"
      textAlign="center"
      py={8}
    >
      <AlertIcon boxSize="40px" mr={0} />
      <AlertTitle my={4} fontSize="lg">
        {t("resetPassword.successAlert.title")}
      </AlertTitle>
      <AlertDescription mb={4}>
        <Text>
          <Trans
            i18nKey="resetPassword.successAlert.description"
            components={{ bold: <Text as="span" fontWeight="bold" /> }}
            values={{ email }}
          />
        </Text>
      </AlertDescription>
      <AlertDescription>
        <Text>{t("resetPassword.successAlert.spamWarning")}</Text>
      </AlertDescription>
    </Alert>
  ) : (
    <Container maxW="md">
      <Heading size="lg" textAlign="center" mb={8}>
        {t("resetPassword.title")}
      </Heading>
      <Text mb={8} textAlign="center">
        {t("resetPassword.description")}
      </Text>
      <form onSubmit={onSubmit}>
        <FormControl isInvalid={!!errors.email}>
          <FormLabel htmlFor="email">{t("resetPassword.emailLabel")}</FormLabel>
          <Input
            id="email"
            size="lg"
            type="email"
            border="1px"
            borderColor="gray.300"
            errorBorderColor="red.500"
            borderRadius={4}
            {...register("email")}
          />
          {!!errors.email && errors.email?.message ? (
            <FormErrorMessage>{errors.email.message}</FormErrorMessage>
          ) : null}
        </FormControl>
        <Button
          mt={8}
          type="submit"
          colorScheme="blue"
          size="lg"
          w="full"
          isLoading={isSubmitting}
          borderRadius={4}
        >
          {t("resetPassword.submitButton")}
        </Button>
      </form>
    </Container>
  );
};
