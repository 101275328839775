import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  GridItem,
  HStack,
  Icon,
  Tag,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { useUpdateEffect, useWindowsFocus } from "@reactuses/core";
import dayjs from "dayjs";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/chackra-ui-button";
import {
  Stripe_InvoiceStatus,
  useInvoicesQuery,
} from "../../graphql/generated/user-hooks";
import { formatPrice } from "../../helpers/format-price";
import {
  mapInvoiceStatusToBadgeColor,
  mapInvoiceStatusToText,
} from "../../helpers/invoice-status";
import { useSource } from "../../helpers/use-source";
export const InvoiceSection = () => {
  const source = useSource();
  const focused = useWindowsFocus();
  const { t } = useTranslation();
  const toast = useToast();
  const [fetchMoreLoading, setFetchMoreLoading] = useState(false);
  const { data, loading, fetchMore, refetch } = useInvoicesQuery({
    fetchPolicy: "network-only",
    context: { endpoint: "fitnext" },
    onError: (error) => {
      toast({
        title: error.message,
        status: "error",
        isClosable: true,
      });
    },
  });

  useEffect(() => {
    setFetchMoreLoading(false);
  }, [data?.invoices.edges]);

  useUpdateEffect(() => {
    if (focused) {
      refetch();
    }
  }, [focused]);

  return (
    <Box w="full" pb={data && data.invoices.edges.length > 0 ? 2 : 6}>
      <Text fontSize="2xl" fontWeight="600" color="gray.900" mb={8}>
        {t("invoices.title")}
      </Text>
      <VStack w="full" spacing={4} mt={8} mb={4} align="start">
        {loading || !data ? (
          <HStack>
            <CircularProgress isIndeterminate color="blue.500" size={4} />
            <Text>{t("invoices.loading")}</Text>
          </HStack>
        ) : data.invoices.edges.length > 0 ? (
          <VStack w="full" spacing={4}>
            {data.invoices.edges.map(({ node }, index) => {
              const showInvoiceUrl =
                node.hostedInvoiceUrl &&
                (node.status === Stripe_InvoiceStatus.Paid ||
                  node.status === Stripe_InvoiceStatus.Open ||
                  (node.status === Stripe_InvoiceStatus.Uncollectible &&
                    source === "kiosk"));

              return (
                <Fragment key={node.id}>
                  <Grid
                    templateColumns={{
                      base: "1fr 1fr",
                      md: "1fr 1fr 1fr 1fr",
                    }}
                    w="full"
                    alignItems="center"
                    py={1}
                    rowGap={1}
                    columnGap={2}
                  >
                    <GridItem display="flex" justifyContent="flex-start">
                      <Text fontWeight={500} fontSize="lg">
                        {dayjs(node.createdAt).format("DD/MM/YYYY")}
                      </Text>
                    </GridItem>
                    <GridItem
                      display="flex"
                      justifyContent={{ base: "flex-end" }}
                    >
                      <Text fontWeight={500} fontSize="lg">
                        {formatPrice({
                          t,
                          amount: node.amountDue,
                        })}
                      </Text>
                    </GridItem>
                    <GridItem
                      display="flex"
                      justifyContent={{ base: "flex-start", md: "flex-end" }}
                    >
                      {node.status ? (
                        <Tag
                          colorScheme={mapInvoiceStatusToBadgeColor(
                            node.status
                          )}
                        >
                          {mapInvoiceStatusToText({ t, status: node.status })}
                        </Tag>
                      ) : null}
                    </GridItem>
                    <GridItem display="flex" justifyContent="flex-end">
                      {showInvoiceUrl ? (
                        <Button
                          {...(source === "kiosk" || source === "app"
                            ? {
                                onClick: () => {
                                  (window as any).invoiceHandler?.postMessage(
                                    node.hostedInvoiceUrl
                                  );
                                },
                              }
                            : {
                                href: node.hostedInvoiceUrl,
                                target: "_blank",
                                as: "a",
                              })}
                          variant="link"
                          colorScheme="blue"
                          fontWeight={500}
                          textDecoration="underline"
                          borderRadius={4}
                          rightIcon={
                            <Icon h={4} w={4}>
                              <ArrowTopRightOnSquareIcon />
                            </Icon>
                          }
                        >
                          {t("invoices.viewInvoice")}
                        </Button>
                      ) : node.status === Stripe_InvoiceStatus.Uncollectible ? (
                        <Button
                          onClick={() => {
                            toast({
                              title: t("invoices.invalidInvoiceMessage"),
                              status: "info",
                            });
                          }}
                          variant="link"
                          color="gray.500"
                          fontWeight={500}
                          textDecoration="underline"
                          borderRadius={4}
                        >
                          {t("invoices.unavailable")}
                        </Button>
                      ) : null}
                    </GridItem>
                  </Grid>
                  {index < data.invoices.edges.length - 1 ? <Divider /> : null}
                </Fragment>
              );
            })}
            {data.invoices.pageInfo.hasNextPage ? (
              <Button
                mt={6}
                w="full"
                variant="outline"
                isLoading={fetchMoreLoading}
                onClick={() => {
                  setFetchMoreLoading(true);
                  fetchMore({
                    variables: { after: data.invoices.pageInfo.endCursor },

                    updateQuery: (previousResult, { fetchMoreResult }) => {
                      if (!fetchMoreResult) {
                        return previousResult;
                      }

                      const previousEdges = previousResult.invoices.edges;
                      const fetchMoreEdges = fetchMoreResult.invoices.edges;

                      fetchMoreResult.invoices.edges = [
                        ...previousEdges,
                        ...fetchMoreEdges,
                      ];

                      return { ...fetchMoreResult };
                    },
                  });
                }}
              >
                {t("invoices.loadMore")}
              </Button>
            ) : null}
          </VStack>
        ) : (
          <Text>{t("invoices.noInvoices")}</Text>
        )}
      </VStack>
    </Box>
  );
};
